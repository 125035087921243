<template>
  <div class="productRegionList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="false"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="产品域名称" v-model="searchParams.productRegionName"></v-input>
        <v-select2 label="授权对象" v-model="searchParams.regionId" v-bind="regionIdParams"></v-select2>
        <v-select2 label="用户端APP" v-model="searchParams.userAppCode" v-bind="userAppCodeParams"></v-select2>
        <v-select2 label="服务端APP" v-model="searchParams.serviceAppCode" v-bind="serviceAppCodeParams"></v-select2>
        <v-select2 label="商家端APP" v-model="searchParams.businessAppCode" v-bind="businessAppCodeParams"></v-select2>
        <v-datepicker label="创建时间" :maxDate="maxDate" type="rangedatetimer" :startTime.sync="searchParams.startInTime" :endTime.sync="searchParams.endInTime"></v-datepicker>
        <v-input label="创建人" v-model="searchParams.inuser"></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, regionIdURL, APPURL } from './api'

export default {
  name: 'ProductRegionList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      maxDate: new Date(),
      regionIdParams: {
        searchUrl: regionIdURL,
        request: {
          text: 'regionName',
          value: 'regionId'
        },
        response: {
          text: 'regionName'
        }
      },
      userAppCodeParams: {
        searchUrl: `${APPURL}` + 1,
        request: {
          text: 'appName'
        },
        response: {
          text: 'appName'
        }
      },
      serviceAppCodeParams: {
        searchUrl: `${APPURL}` + 2,
        request: {
          text: 'appName'
        },
        response: {
          text: 'appName'
        }
      },
      businessAppCodeParams: {
        searchUrl: `${APPURL}` + 3,
        request: {
          text: 'appName'
        },
        response: {
          text: 'appName'
        }
      },
      searchParams: {
        productRegionName: '',
        regionId: '',
        userAppCode: '',
        serviceAppCode: '',
        businessAppCode: '',
        startInTime: '',
        endInTime: '',
        inuser: ''
      },
      headers: [
        {
          prop: 'productRegionName',
          label: '产品域名称'
        },
        {
          prop: 'regionRame',
          label: '授权对象'
        },
        {
          prop: 'userAppName',
          label: '用户端APP'
        },
        {
          prop: 'serviceAppName',
          label: '服务端APP'
        },
        {
          prop: 'businessAppName',
          label: '商家端APP'
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'inuserName',
          label: '创建人'
        }
      ]
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'productRegionForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'productRegionForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
